import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);
  const reset = (newValues = initialState) => {
    setValues(newValues);
  };
  return [
    fields,
    function (event) {
      try {
        const { value, type, checked } = event.target;
        setValues({
          ...fields,
          [event.target.id]: type === "checkbox" ? checked : value,
        });
      } catch (err) {}
    },
    reset,
  ];
}
