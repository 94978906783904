import React from "react";
import Link from "@mui/material/Link";
import { useFormFields } from "../../components/hooks/InputFieldsHooks";
import useSendPost from "../../components/hooks/DefaultAxiosRequest";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../components/hooks/loadingbar/Spinner";
function ForgotPassword({ goToLogin }) {
  let data = {
    email: "",
  };
  let [fields, setFormData, reset] = useFormFields(data);
  const [loading, sendPost] = useSendPost(
    "/api/user/reset_account",
    fields,
    true,
    true
  );
  function resetAccount(e) {
    e.preventDefault();

    sendPost((promise) => {
      console.log(JSON.stringify(promise));
      if (promise.code === 0) {
        reset(data);
        goToLogin(promise.message);
      } else {
        toast.error(promise.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }
  return (
    <>
      <Spinner isLoading={loading} />
      <ToastContainer />

      <form onSubmit={resetAccount} autoComplete="off">
        <div className="form-group">
          <label htmlFor="email_address" className="login_label">
            E-Mail Address :
          </label>
          <div className="col-md-">
            <input
              type="email"
              id="email"
              placeholder="Enter Your Email address"
              className="form-control form-control-lg ctn_login"
              name="email"
              value={fields.email}
              onChange={setFormData}
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 ">
            <button
              disabled={loading}
              type="submit"
              className="btn copco_btn btn-lg"
            >
              Reset Password
            </button>
          </div>
        </div>
        <div className="row login_input_link">
          <div className="col-md-12">
            <center>
              <Link onClick={goToLogin} className="btn_link">
                Go To Login
              </Link>
            </center>
          </div>
        </div>
      </form>
    </>
  );
}

export default ForgotPassword;
