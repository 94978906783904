import useAuth from "../../components/hooks/useAuth ";
import { useLocation, Navigate, Outlet } from "react-router-dom";
const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation(); //

  let count = Object.keys(auth).length;
  if (count === 0) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <Outlet />;
};

export default RequireAuth;
