import React from "react";
import "./spinner.css";
function Spinner({ isLoading }) {
  return (
    <div>
      <div
        style={{ display: isLoading ? "flex" : "none" }}
        className="dialog_modal"
      >
        <div className="modal-dialog-content">
          <div className="loader"></div>
          <div className="modal-text">Processing Request...</div>
        </div>
      </div>
    </div>
  );
}

export default Spinner;
