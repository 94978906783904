import React, { useState, useEffect, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirm } from "react-confirm-box";
import Spinner from "../../components/hooks/loadingbar/Spinner";
import useFetchHook from "../../components/hooks/HttpRequesthook";
import { useFormFields } from "../../components/hooks/InputFieldsHooks";
import { SocketContext } from "../../components/hooks/SocketProvider";
import axios from "../../components/api/axios";

function StudentManagement({ sk }) {
  const socket = useContext(SocketContext);
  const [received, setReceived] = useState(false);
  useEffect(() => {
    // subscribe to socket events
    if (!socket.connected) {
      socket.connect();
    }

    socket.on("on_bio_enroll", (message) => {
      setPushing(false);

      data = {
        level: "",
        parent_contact: "",
        student_surname: "",
        student_sname: "",
        student_fname: "",
        bio_status: "NA",
        student_admission_no: "",
      };
      if (!received) {
        setReceived(true);
        reset(data);
        toast.success("Bio Enrollment Success", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [sk]);

  let api_url = "/api/student/update";
  const options = {
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };
  let data = {
    level: "",
    parent_contact: "",
    student_surname: "",
    student_sname: "",
    student_fname: "",
    bio_status: "NA",
    student_admission_no: "",
  };
  let [fields, setFormData, reset] = useFormFields(data);

  const [pushing, setPushing] = useState(false);

  const pushStudentToBioDevice = async (e) => {
    e.preventDefault();
    try {
      const result = await confirm(
        "Are you sure your wish enroll this student bio?",
        options
      );

      if (!result) {
        return;
      }
      setReceived(false);
      setPushing(true);
      let req_data = {
        student_admission_no: fields.student_admission_no,
      };

      await axios.post("/api/student/push_student_to_device", req_data);
    } catch (err) {
      console.error("Error:", err); // Log the error for debugging
      toast.error("Some error occurred while enrolling bio", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } finally {
      //setPushing(false);
    }
  };
  const updateStudent = async (e) => {
    e.preventDefault();
    api_url = "/api/student/update";

    const result = await confirm(
      "Are you sure your wish update this student?",
      options
    );

    if (!result) {
      return;
    }

    sendPost((promise) => {
      //console.log("promise:" + JSON.stringify(promise));
      if (promise.code === 0) {
        toast.success(promise.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset(data);
      } else {
        toast.error(promise.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };
  let query = {
    student_admission_no: "",
  };
  let [query_data, setQueryFields, resetSearch] = useFormFields(query);
  const [student_id, setStudentid] = useState(0);
  const searchStudent = async (e) => {
    e.preventDefault();
    doSearch();
  };
  const doSearch = () => {
    searcStudent((promise) => {
      if (promise == "") {
        data = {
          level: "",
          parent_contact: "",
          student_surname: "",
          student_sname: "",
          student_fname: "",
          bio_status: "NA",
          student_admission_no: "",
        };
        reset(data);
        toast.error("Student does not exist!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        data = {
          level: promise.level,
          parent_contact: promise.parent_contact,
          student_surname: promise.student_surname,
          student_sname: promise.student_sname,
          student_id: promise.student_id,
          student_fname: promise.student_fname,
          bio_status: promise.bio_status,
          student_admission_no: promise.student_admission_no,
        };
        reset(data);
        setStudentid(promise.student_id);
      }
    });
  };
  const [searching, searcStudent] = useFetchHook(
    "/api/student/search",
    query_data,
    true,
    true
  );
  const [loading, sendPost] = useFetchHook(api_url, fields, true, true);

  return (
    <div>
      <ToastContainer />
      {<Spinner isLoading={loading || searching || pushing} />}

      <div className="container-fluid">
        <fieldset className="border p-2 " style={{ height: "94%" }}>
          <legend className="w-auto">
            Update Student Details / Enroll Bio
          </legend>
          <div
            className="row mt-5"
            style={{ background: "#F5F5F5", marginLeft: 1, marginRight: 2 }}
          >
            <div className="col-md-7 "></div>
            <div className="col-md-5 ">
              <div className="input-group float-right">
                <input
                  type="search"
                  value={query_data.student_admission_no}
                  onChange={setQueryFields}
                  id="student_admission_no"
                  name="student_admission_no"
                  autoComplete="off"
                  className="form-control rounded"
                  placeholder="Enter Student Admission Number"
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
                <button
                  type="button"
                  className="btn btn-success"
                  data-mdb-ripple-init
                  onClick={searchStudent}
                >
                  <i className="fa fa-search"></i>
                  search
                </button>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="row mt-3">
            <div className="col-md-12">
              <form autoComplete="off">
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="student_admission_no"
                        className="detail_label_style"
                      >
                        Student Admission Num
                      </label>
                      <input
                        placeholder="Enter Student Admission Num"
                        className="form-control"
                        id="student_admission_no"
                        name="student_admission_no"
                        value={fields.student_admission_no}
                        onChange={setFormData}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="student_fname"
                        className="detail_label_style"
                      >
                        Student First Name
                      </label>
                      <input
                        placeholder="Enter Student First Name"
                        className="form-control"
                        id="student_fname"
                        name="student_fname"
                        value={fields.student_fname}
                        onChange={setFormData}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="student_sname"
                        className="detail_label_style"
                      >
                        Student Second Name
                      </label>
                      <input
                        placeholder="Enter Student Second Name"
                        className="form-control"
                        id="student_sname"
                        name="student_sname"
                        value={fields.student_sname}
                        onChange={setFormData}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label
                        htmlFor="student_surname"
                        className="detail_label_style"
                      >
                        Student Sur Name
                      </label>
                      <input
                        placeholder="Enter Student Sur Name"
                        className="form-control"
                        id="student_surname"
                        name="student_surname"
                        value={fields.student_surname}
                        onChange={setFormData}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="m_width" className="detail_label_style">
                        Form:
                      </label>
                      <select
                        id="level"
                        className="form-control"
                        value={fields.level}
                        onChange={setFormData}
                      >
                        <option value="">Select</option>
                        <option value="Form One">Form One</option>
                        <option value="Form Two">Form Two</option>
                        <option value="Form Three">Form Three</option>
                        <option value="Form Four">Form Four</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="m_length" className="detail_label_style">
                        Parent Contact
                      </label>
                      <input
                        placeholder="Parent Contact"
                        className="form-control"
                        id="parent_contact"
                        type="number"
                        name="parent_contact"
                        value={fields.parent_contact}
                        onChange={setFormData}
                        required
                      />
                    </div>
                  </div>
                </div>

                <hr></hr>
                <div className="row">
                  <div className="col-md-6">
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <label
                              htmlFor="m_length"
                              className="detail_label_style"
                            >
                              Bio Enroll Status:
                            </label>
                          </td>
                          <td>
                            <label
                              className="form-control ml-2"
                              style={{
                                background:
                                  fields.bio_status == "N" ||
                                  fields.bio_status == "NA"
                                    ? "#F44336"
                                    : "#43A047",
                                color: "#fff",
                              }}
                            >
                              {fields.bio_status == "A"
                                ? "Enrolled"
                                : "Not Enrolled"}
                            </label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-6">
                    <button
                      id="btn_update"
                      onClick={updateStudent}
                      disabled={
                        pushing || fields.student_admission_no == "" || loading
                      }
                      className="float-right btn btn-primary ml-2"
                      type="submit"
                    >
                      <i className="fa fa-pencil mr-1"></i>
                      Update Student
                    </button>
                  </div>
                </div>
              </form>
              <button
                id="btn_push"
                onClick={pushStudentToBioDevice}
                disabled={
                  pushing ||
                  fields.student_admission_no == "" ||
                  loading ||
                  fields.bio_status === "A"
                }
                className="float-right btn btn-secondary ml-2 pl-4 pr-4"
              >
                <i className="fa fa-indent mr-1"></i>
                Register Bio
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default StudentManagement;
