import { useEffect } from "react";
import { axiosPrivate } from "../../components/api/axios";
import useAuth from "./useAuth ";
import axios from "../../components/api/axios";
import { useNavigate } from "react-router-dom";
const useAxiosPrivate = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          config.headers[
            "Authorization"
          ] = `Bearer ${auth?.user_info?.accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        let code = error?.response?.status;
        if (code === 403 && !prevRequest?.sent) {
          prevRequest.sent = true;

          return axios
            .get("/user/refresh_token")
            .then(({ data }) => {
              //console.log("new array:" + JSON.stringify(data));
              //console.log("old array:" + JSON.stringify(auth));
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + data.accessToken;
              prevRequest.headers["Authorization"] =
                "Bearer " + data.accessToken;
              auth.user_info.accessToken = data.accessToken; //update local storage token
              setAuth(auth);
              return axios(prevRequest);
            })
            .catch((error) => {
              if (error?.response?.status === 401) {
                //this is someone without refresh token
                setAuth({});
                navigate("/login", { replace: true });
              } else {
                return Promise.reject(error);
              }
            });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [auth]);

  return axiosPrivate;
};

export default useAxiosPrivate;
