import React from "react";

import "./sidebar.css";

import SideMenu from "./SideMenu";
import { MenuItem } from "./MenuItem";
//import useAuth from "../../pages/auth/useAuth ";
function Sidebar() {
  let dash_board_menu = MenuItem;
  /*const { auth } = useAuth();

  const is_admin = auth.user_info.user_info.roles === "A";
  let dash_board_menu = MenuItem;
  if (!is_admin) {
    dash_board_menu = MenuItem.filter((menu) => {
      return (
        menu.title.toLowerCase() !== "Users Management".toLowerCase() &&
        menu.title.toLowerCase() !== "Reports".toLowerCase()
      );
    });
  }*/

  return (
    <>
      <div className="sidebar">
        <div className="sidebarWrapper">
          {dash_board_menu.map((item, index) => {
            return <SideMenu item={item} key={index} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Sidebar;
