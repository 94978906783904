import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./pages/auth/Layout";
import Home from "./pages/Home";
import Sidebar from "./components/sidebar/Sidebar";
import Login from "./pages/loggon/Login";
import Topbar from "./components/menu/Topbar";
import StudentManagement from "./pages/students/StudentManagement";
import { Settings } from "@material-ui/icons";
import OopsNoRoute from "./pages/OopsNoRoute";
import AppSettings from "./pages/admin/AppSettings";
import AddStudent from "./pages/students/AddStudent";
import useAuth from "./components/hooks/useAuth ";
import { useEffect } from "react";
import Attendance from "./pages/students/Attendance";
import RequireAuth from "./pages/auth/RequireAuth";
function App() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      //e.preventDefault();
    });

    document.addEventListener("mousemove", () => {
      localStorage.setItem("lastActvity", new Date());
    });
    document.addEventListener("click", () => {
      localStorage.setItem("lastActvity", new Date());
    });

    let timeInterval = setInterval(() => {
      let lastAcivity = localStorage.getItem("lastActvity");
      var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
      var seconds = Math.floor(diffMs / 1000);
      var minute = Math.floor(seconds / 60);
      // console.log(seconds + " sec and " + minute + " min since last activity");
      if (minute === 5) {
        clearInterval(timeInterval);
        setAuth({});
        navigate("/login", { replace: true });
      }
    }, 1000);
  }, []);
  const HomeDashBoard = () => (
    <>
      <Topbar />
      <div className="div_container">
        <Sidebar />
        <div className="home">
          <Routes>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/student/add" element={<AddStudent />} />
            <Route
              exact
              path="/student/manage"
              element={<StudentManagement sk={Math.random()} />}
            />
            <Route exact path="/student/attendance" element={<Attendance />} />

            <Route exact path="/admin/settings" element={<AppSettings />} />
          </Routes>
        </div>
      </div>
    </>
  );
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route exact path="/login" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/*" element={<HomeDashBoard />} />
          </Route>

          <Route path="*" element={<OopsNoRoute />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
