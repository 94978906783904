import React, { useState } from "react";
import ChangeDefaultPassword from "./ChangeDefaultPassword";

import ForgotPassword from "./ForgotPassword";
import LogonForm from "./LogonForm";
import { ToastContainer, toast } from "react-toastify";
import ValidateAccount from "./ValidateAccount";
function Login() {
  const [page, setPage] = useState("L");
  const [id, setUserId] = useState(0);
  const [validate_ac, setValidateAc] = useState(false);
  const [title, setTitle] = useState("Account Login");
  const [sub_title, setSubTitle] = useState("Login to your account below");

  function changePassword(e) {
    e.preventDefault();
    setPage("FP");
    setTitle("Acount Reset");
    setSubTitle("Reset your acount below");
  }
  function goToLogin(message) {
    // e.preventDefault();
    setTitle("Account Login");
    setSubTitle("Login to your account below");
    if (message !== "NA") {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setValidateAc(false);
    setPage("L");
  }

  function goToSetPassord(id) {
    setUserId(id);
    setPage("CH");

    setTitle("Change Password");
    setSubTitle("Change Default Password");
  }
  function goToResetAccount(value, user_id) {
    setValidateAc(value);
    setUserId(user_id);
    console.log("value:" + value);
    if (value) {
      setTitle("Validate Account");
      setSubTitle("Validate OTP from your email");
    } else {
      setTitle("Account Login");
      setSubTitle("Login to your account below");
    }
  }
  const base_url = process.env.REACT_APP_API_URL;
  return (
    <div className="container-fluid main_login">
      <ToastContainer />
      <div className="row">
        <div className="col-md-6 l_div">
          <img
            className="login_img"
            src={base_url + "/api/image_viewer/front_end/logo.jpg"}
            alt="dfasfa"
          />
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-12">
              <h1 className="title_header mt-5">NGATIA GIRLS </h1>
              <h3 className="sub_title">Attendance Biometric Enrollment</h3>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <h1 className="login_title">{title}</h1>
              <h6 className="login_sub_title">{sub_title}</h6>
              {page === "L" ? (
                validate_ac ? (
                  <ValidateAccount
                    goToLogin={goToLogin}
                    goToResetAccount={goToResetAccount}
                    id={id}
                  />
                ) : (
                  <LogonForm
                    goToSetPassord={goToSetPassord}
                    goToResetAccount={goToResetAccount}
                    changePassword={changePassword}
                  />
                )
              ) : page === "FP" ? (
                <ForgotPassword goToLogin={goToLogin} />
              ) : (
                <ChangeDefaultPassword id={id} goToLogin={goToLogin} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h6 style={{ fontSize: 18 }}>
            Powered By dmurithi856@gmail.com @2024
          </h6>
        </div>
      </div>
    </div>
  );
}

export default Login;
