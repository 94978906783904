import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirm } from "react-confirm-box";
import Spinner from "../../components/hooks/loadingbar/Spinner";
import useFetchHook from "../../components/hooks/HttpRequesthook";
import { useFormFields } from "../../components/hooks/InputFieldsHooks";
function AddStudent() {
  let api_url = "/api/student/create";
  const options = {
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };
  let data = {
    level: "",
    parent_contact: "",
    student_surname: "",
    student_sname: "",
    student_fname: "",
    student_admission_no: "",
  };
  let [fields, setFormData, reset] = useFormFields(data);
  const newStudent = async (e) => {
    e.preventDefault();
    const result = await confirm(
      "Are you sure your wish add this student?",
      options
    );

    if (!result) {
      return;
    }
    console.log(JSON.stringify(fields));
    sendPost((promise) => {
      console.log("promise:" + JSON.stringify(promise));
      if (promise.code === 0) {
        toast.success(promise.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        reset(data);
      } else {
        toast.error(promise.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    });
  };

  const [loading, sendPost] = useFetchHook(api_url, fields, true, true);
  return (
    <div>
      <ToastContainer />
      {<Spinner isLoading={loading} />}
      <div className="container-fluid">
        <div className="card card-form">
          <div className="card-header ">
            <i className="fa fa-user mr-3"></i>
            Student Registration
          </div>
          <div className="card-body">
            <form autoComplete="off">
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label
                      htmlFor="student_admission_no"
                      className="detail_label_style"
                    >
                      Student Admission Num
                    </label>
                    <input
                      placeholder="Enter Student Admission Num"
                      className="form-control"
                      id="student_admission_no"
                      name="student_admission_no"
                      value={fields.student_admission_no}
                      onChange={setFormData}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label
                      htmlFor="student_fname"
                      className="detail_label_style"
                    >
                      Student First Name
                    </label>
                    <input
                      placeholder="Enter Student First Name"
                      className="form-control"
                      id="student_fname"
                      name="student_fname"
                      value={fields.student_fname}
                      onChange={setFormData}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label
                      htmlFor="student_sname"
                      className="detail_label_style"
                    >
                      Student Second Name
                    </label>
                    <input
                      placeholder="Enter Student Second Name"
                      className="form-control"
                      id="student_sname"
                      name="student_sname"
                      value={fields.student_sname}
                      onChange={setFormData}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label
                      htmlFor="student_surname"
                      className="detail_label_style"
                    >
                      Student Sur Name
                    </label>
                    <input
                      placeholder="Enter Student Sur Name"
                      className="form-control"
                      id="student_surname"
                      name="student_surname"
                      value={fields.student_surname}
                      onChange={setFormData}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="m_width" className="detail_label_style">
                      Form:
                    </label>
                    <select
                      id="level"
                      className="form-control"
                      value={fields.level}
                      onChange={setFormData}
                    >
                      <option value="">Select</option>
                      <option value="Form One">Form One</option>
                      <option value="Form Two">Form Two</option>
                      <option value="Form Three">Form Three</option>
                      <option value="Form Four">Form Four</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="m_length" className="detail_label_style">
                      Parent Contact
                    </label>
                    <input
                      placeholder="Parent Contact"
                      className="form-control"
                      id="parent_contact"
                      type="number"
                      name="parent_contact"
                      value={fields.parent_contact}
                      onChange={setFormData}
                      required
                    />
                  </div>
                </div>
              </div>

              <hr></hr>

              <button
                onClick={newStudent}
                disabled={loading}
                className="float-right btn btn-primary ml-2"
                type="submit"
              >
                <i className="fa fa-plus-circle mr-1"></i>
                Register Student
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddStudent;
