import React from "react";
import { useFormFields } from "../../components/hooks/InputFieldsHooks";
import useSendPost from "../../components/hooks/DefaultAxiosRequest";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../components/hooks/loadingbar/Spinner";
function ChangeDefaultPassword({ goToLogin, id }) {
  let data = {
    id: 0,
    old_password: "",
    new_password: "",
    confirm_pwd: "",
  };
  let [fields, setFormData, reset] = useFormFields(data);
  const [loading, sendPost] = useSendPost(
    "/api/user/change_password",
    fields,
    true,
    true
  );
  function changePassword(e) {
    e.preventDefault();
    fields["id"] = id;

    sendPost((promise) => {
      if (promise.code === 0) {
        toast.success(promise.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        reset(data);
        goToLogin();
      } else {
        toast.error(promise.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }
  return (
    <>
      <ToastContainer />
      <Spinner isLoading={loading} />

      <form autoComplete="off" onSubmit={changePassword}>
        <div className="form-group">
          <label htmlFor="password" className="login_label">
            Current Password
          </label>
          <div className="row">
            <div className="col-md-12">
              <input
                type="password"
                id="old_password"
                autoComplete="off"
                placeholder="Enter Current Password"
                className="form-control form-control-lg ctn_login"
                name="old_password"
                value={fields.old_password}
                onChange={setFormData}
                required
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="password" className="login_label">
            New Password
          </label>
          <div className="row">
            <div className="col-md-12">
              <input
                type="password"
                id="new_password"
                autoComplete="off"
                placeholder="Enter New Password"
                className="form-control form-control-lg ctn_login"
                name="new_password"
                value={fields.new_password}
                onChange={setFormData}
                required
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="password" className="login_label">
            Confirm Password
          </label>
          <div className="row">
            <div className="col-md-12">
              <input
                type="password"
                id="confirm_pwd"
                placeholder="Re-enter Password"
                className="form-control form-control-lg ctn_login"
                name="confirm_pwd"
                value={fields.confirm_pwd}
                onChange={setFormData}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 ">
            <button
              disabled={loading}
              type="submit"
              className="btn btn copco_btn btn-lg"
            >
              Change Password
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default ChangeDefaultPassword;
