import React from "react";
import Link from "@mui/material/Link";
import { useFormFields } from "../../components/hooks/InputFieldsHooks";
import useSendPost from "../../components/hooks/DefaultAxiosRequest";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../components/hooks/loadingbar/Spinner";
import useAuth from "../../components/hooks/useAuth ";

import { useNavigate } from "react-router-dom";
function ValidateAccount({ goToResetAccount, goToLogin, id }) {
  const navigate = useNavigate();
  const { setAuth } = useAuth(); //to globaly access token
  let data = {
    otp: "",
    id: id,
  };
  let [fields, setFormData, reset] = useFormFields(data);
  const [loading, sendPost] = useSendPost(
    "/api/user/otp_validation",
    fields,
    true,
    true
  );
  function resetAccount(e) {
    e.preventDefault();
    //console.log("data " + JSON.stringify(fields));
    sendPost((promise) => {
      if (promise.code === 0) {
        reset(data);
        const user_info = promise;
        goToResetAccount(false);
        setAuth({ user_info });

        navigate("/home", { replace: true });
        /*toast.message(promise.message, {
          position: toast.POSITION.TOP_RIGHT,
        });*/
      } else {
        toast.error(promise.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
  }
  return (
    <div>
      <>
        <ToastContainer />
        {<Spinner isLoading={loading} />}

        <form onSubmit={resetAccount} autoComplete="off">
          <div className="form-group">
            <label htmlFor="email_address" className="login_label">
              OTP :
            </label>
            <div className="row">
              <div className="col-md-12">
                <input
                  type="text"
                  id="otp"
                  placeholder="Enter OTP from your email"
                  className="form-control form-control-lg ctn_login"
                  name="otp"
                  value={fields.otp}
                  onChange={setFormData}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 ">
              <button
                disabled={loading}
                type="submit"
                className="btn btn copco_btn btn-lg"
              >
                Validate Account
              </button>
            </div>
          </div>

          <div className="row login_input_link">
            <div className="col-md-12">
              <center>
                <Link onClick={() => goToLogin("NA")} className="btn_link">
                  Go To Login
                </Link>
              </center>
            </div>
          </div>
        </form>
      </>
    </div>
  );
}

export default ValidateAccount;
