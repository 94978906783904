import React from "react";

import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SocketContext, socket } from "./components/hooks/SocketProvider";
import { AuthProvider } from "./components/hooks/AuthContext";
//npx create-react-app my-react-app --template cra-template

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <AuthProvider>
      <Routes basename="school">
        <Route
          path="/*"
          element={
            <SocketContext.Provider value={socket}>
              <App />
            </SocketContext.Provider>
          }
        />
      </Routes>
    </AuthProvider>
  </Router>
);
