import React, { useContext } from "react";
import "./topbar.css";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Home } from "@material-ui/icons";

import axios from "../api/axios";
import { confirm } from "react-confirm-box";
import { SocketContext } from "../hooks/SocketProvider";
import useAuth from "../hooks/useAuth ";

function Topbar() {
  const navigate = useNavigate();
  const socket = useContext(SocketContext);
  // const refresh = useRefreshToken();

  const [re_load, setReload] = useState(0);

  const { auth, setAuth } = useAuth();
  const options = {
    labels: {
      confirmable: "Confirm",
      cancellable: "Cancel",
    },
  };

  async function logOut() {
    const result = await confirm("Are you sure you wish to log out?", options);
    //const answer = window.confirm("");
    if (!result) {
      return;
    }
    axios
      .post("/api/user/log_out", { id: auth.user_info.user_info.id })
      .then((resp) => {
        socket.disconnect();
        setAuth({});
        navigate("/login", { replace: true });
      })
      .catch((err) => {
        // Handle Error Here
        console.error("403 dem hera now:" + err.response.status);
      });
  }
  async function goHome() {
    navigate("/home");
    // refresh();
    setReload(Math.floor(Math.random() * 10000));
  }
  return (
    <div className="topbar">
      <div className="topbarWrapper">
        <div className="topbarLeft">
          <span className="logo title_logo">
            NGATIA GIRLS School Management
          </span>
        </div>
        <div className="topRight">
          <span className="topbarIconContainer mr-8 ">Logged In as :</span>
          <span className="topbarIconContainer mr-8 welcome_span">
            {auth?.user_info?.user_info?.username}
          </span>

          <div className="topbarIconContainer" onClick={() => goHome()}>
            <Home re_load={re_load} />
          </div>

          <button
            onClick={() => logOut()}
            className="btn btn-primary float-right"
          >
            <i className="fa fa-sign-out fa_edit"></i>
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
