import React from "react";

import Link from "@mui/material/Link";
import { useFormFields } from "../../components/hooks/InputFieldsHooks";
import useSendPost from "../../components/hooks/DefaultAxiosRequest";
import Spinner from "../../components/hooks/loadingbar/Spinner";
import { ToastContainer, toast } from "react-toastify";

function LogonForm({ changePassword, goToSetPassord, goToResetAccount }) {
  let data = {
    email: "",
    password: "",
  };
  let [fields, setFormData, reset] = useFormFields(data);
  const [loading, sendPost] = useSendPost(
    "/api/user/login",
    fields,
    true,
    true
  );

  function handleSubmit(e) {
    e.preventDefault();

    sendPost((promise) => {
      if (promise.code === 0 || promise.code === 2) {
        if (promise.code === 2) {
          toast.success(promise.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          goToSetPassord(promise.user_id);
        } else {
          toast.success(promise.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          goToResetAccount(true, promise.user_id);
        }

        reset(data);
      } else {
        toast.error(promise.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });

    // goToSetPassord();
    /* if (email === "dev@yahoo.com" && password === "12345") {
        localStorage.setItem("token", "1234");
        navigate("/dashboard");
      }*/
  }

  return (
    <>
      {<Spinner isLoading={loading} />}
      <ToastContainer />
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className="form-group">
          <label htmlFor="email" className="login_label">
            E-Mail Address :
          </label>
          <div className="row">
            <div className="col-md-12">
              <input
                type="text"
                id="email"
                placeholder="Enter Your Email address"
                className="form-control form-control-lg ctn_login"
                name="email"
                value={fields.email}
                onChange={setFormData}
                required
              />
            </div>
          </div>
        </div>

        <div className="form-group login_input">
          <div className="row">
            <div className="col-md-7">
              <label htmlFor="password" className="login_label">
                Password :
              </label>
            </div>
            <div className="col-md-5">
              <div className="float-right">
                <Link onClick={changePassword} className="btn_link">
                  Forgot Password?
                </Link>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-12">
              <input
                type="password"
                id="password"
                placeholder="Enter Your Password"
                className="form-control form-control-lg ctn_login"
                name="password"
                value={fields.password}
                onChange={setFormData}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 ">
            <button type="submit" className="btn copco_btn btn-lg">
              Login
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

export default LogonForm;
