import { useState } from "react";
import useAxiosPrivate from "../../components/hooks/useAxiosPrivate";

const useFetchHook = (ENDPOINT_URL, post_data, is_post_or_get) => {
  const [loading, isLoading] = useState(false);
  let axiosPrivate = useAxiosPrivate();
  const sendPost = async (next) => {
    if (is_post_or_get) {
      isLoading(true);
      const resp = await sendPostRequest();

      next(resp.data);
      isLoading(false);
    } else {
      const getresp = await sendGetRequest();
      isLoading(false);
      next(getresp.data);
    }
  };
  async function sendGetRequest() {
    if (Object.keys(post_data).length > 0) {
      try {
        const response = await axiosPrivate.get(ENDPOINT_URL, {
          data: post_data,
        });
        return response;
      } catch (err) {
        return {
          code: 1,
          message: err.message,
        };
      }
    } else {
      try {
        const response = await axiosPrivate.get(ENDPOINT_URL);
        return response;
      } catch (err) {
        return {
          code: 1,
          message: err.message,
        };
      }
    }
  }
  async function sendPostRequest() {
    try {
      const response = await axiosPrivate.post(ENDPOINT_URL, post_data);
      return response;
    } catch (err) {
      return {
        code: 1,
        message: err.message,
      };
    }
  }

  return [loading, sendPost];
};

export default useFetchHook;
