import React, { useState } from "react";
import { useFormFields } from "../../components/hooks/InputFieldsHooks";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dateFormat from "dateformat";
import axios from "../../components/api/axios";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../components/hooks/loadingbar/Spinner";
function Attendance() {
  const search = (data) => {
    if (data.length === 0) {
      return [];
    } else {
      return data.filter((job) => job.date.toLowerCase().includes(query));
    }
  };
  const [pushing, setPushing] = useState(false);
  const [date_from, setDateFrom] = useState(new Date());
  const [date_to, setDateTo] = useState(new Date());
  const [attendance, setAttendance] = useState([]);
  /* let users = [
    {
      id: 1,
      date: "2024-04-09",
      time_out: "08:00 AM",
      time_in: "05:00 PM",
    },
  ];*/
  let query = {
    student_admission_no: "",
  };
  let [query_data, setQueryFields, resetSearch] = useFormFields(query);
  const searchAttedance = async (e) => {
    e.preventDefault();
    let dateTo = dateFormat(date_to, "yyyy-mm-dd");
    let dateFrom = dateFormat(date_from, "yyyy-mm-dd");
    let updatedQuery = {
      ...query_data, // Spread existing fields
      to_date: dateTo,
      from_date: dateFrom,
    };
    try {
      setPushing(true);
      let response = await axios.post("/api/auth/attendance", updatedQuery);
      if (response.data.length == 0) {
        toast.error("No Attendance Found!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setAttendance([]);
      } else {
        setAttendance(response.data);
      }
      setPushing(false);
    } catch (err) {
      console.error("Error:", err); // Log the error for debugging
      toast.error("Some error occurred while", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
  return (
    <>
      <ToastContainer />
      {<Spinner isLoading={pushing} />}

      <div className="container-fluid" style={{ height: "95%" }}>
        <fieldset className="border p-2 " style={{ height: "94%" }}>
          <legend className="w-auto">Student Attendance Report</legend>
          <div
            className="row mt-1"
            style={{ background: "#F5F5F5", marginLeft: 1, marginRight: 2 }}
          >
            <div className="col-md-4 mt-3">
              <div className="form-group">
                <label htmlFor="serial" className="label_style mr-2">
                  Date From:
                </label>
                <DatePicker
                  className="form-input"
                  selected={date_from}
                  onChange={(date) => setDateFrom(date)}
                  maxDate={new Date()}
                  placeholderText="Select Date From"
                />
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="form-group">
                <label htmlFor="serial" className="label_style mr-2">
                  Date To:
                </label>
                <DatePicker
                  className="form-input"
                  selected={date_to}
                  onChange={(date) => setDateTo(date)}
                  maxDate={new Date()}
                  placeholderText="Select Date To"
                />
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="input-group ">
                <input
                  type="search"
                  value={query_data.student_admission_no}
                  onChange={setQueryFields}
                  id="student_admission_no"
                  name="student_admission_no"
                  autoComplete="off"
                  className="form-control rounded"
                  placeholder="Enter Student Admission Number"
                  aria-label="Search"
                  aria-describedby="search-addon"
                />
                <button
                  type="button"
                  className="btn btn-success"
                  data-mdb-ripple-init
                  onClick={searchAttedance}
                >
                  <i className="fa fa-search"></i>
                  search
                </button>
              </div>
            </div>
          </div>

          <hr></hr>
          <div className="row">
            <div className="col-md-12">
              <table className="table table-striped table-sm" id="demo_table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Time In</th>
                    <th scope="col">Time Out</th>
                  </tr>
                </thead>
                <tbody>
                  {attendance.length > 0 ? (
                    attendance.map((job) => (
                      <tr key={job.id}>
                        <td>{job.log_date}</td>
                        <td>{job.time_in}</td>

                        <td>{job.time_out}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No Attendance
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>
      </div>
    </>
  );
}

export default Attendance;
