import React from "react";

function Home() {
  return (
    <div
      style={{
        background: "#FFF8E1",
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ margin: 0 }}>Student Biometric Enrollment</h1>
    </div>
  );
}

export default Home;
