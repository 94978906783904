import React from "react";
import {
  SettingsApplications,
  Group,
  Home,
  PersonAdd,
  LocalLibrary,
  Report,
  ReportRounded,
  TrendingUp,
} from "@material-ui/icons";

export const MenuItem = [
  {
    title: "Students Management",
    up_icon: "fa fa-angle-double-up",
    down_icon: "fa fa-angle-double-down",
    menu_item: [
      {
        title: "New",
        path: "/student/add",
        icon: <PersonAdd className="sidebarIcon" />,
      },
      {
        title: "Manage",
        path: "/student/manage",
        icon: <LocalLibrary className="sidebarIcon" />,
      },
    ],
  },
  {
    title: "Attendance",
    up_icon: "fa fa-angle-double-up",
    down_icon: "fa fa-angle-double-down",
    menu_item: [
      {
        title: "Student Attendance",
        path: "/student/attendance",
        icon: <TrendingUp className="sidebarIcon" />,
      },
    ],
  },
];
