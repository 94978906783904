import React, { useState } from "react";
import { Link } from "react-router-dom";

function SideMenu({ item }) {
  const [subnav, setSubnav] = useState(true);

  const showSubnav = () => setSubnav(!subnav);
  return (
    <>
      <div className="sidebarMenu">
        <h3 className="sidebarTitle" onClick={showSubnav}>
          {item.title}
          <span>
            <i
              className={
                subnav
                  ? item.up_icon + " fa-2x nav_icon"
                  : item.down_icon + " fa-2x nav_icon"
              }
            ></i>
          </span>
        </h3>
        {subnav &&
          item.menu_item.map((item, index) => {
            return (
              <ul className="sidebarList" key={index}>
                <Link to={item.path} re className="sidebarListItem" key={index}>
                  {item.icon}
                  <span className="titleText">{item.title}</span>
                </Link>
              </ul>
            );
          })}
      </div>
    </>
  );
}

export default SideMenu;
